import React from "react";
import "./App.css";
import {
  Grid,
  Container,
  ThemeProvider,
  createMuiTheme,
  Link,
  Box,
  makeStyles,
} from "@material-ui/core";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Route,
} from "react-router-dom";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import ShopList from "./components/ShopList";
import ShopDetail from "./components/ShopDetail";
import Impressum from "./components/Impressum";
import ScrollTop from "./components/ScrollTop";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import DataPrivacy from "./components/DataPrivacy";
import CookieConsent from "./components/CookieConsent";

const client = new ApolloClient({
  uri: "https://data.tzdesign.de/graphql",
  cache: new InMemoryCache(),
});

const theme = createMuiTheme({
  typography: {
    fontFamily: ["SF Pro Rounded", "Varela Round", "sans-serif"].join(","),
  },
  shape: {
    borderRadius: 10,
  },
  palette: {
    primary: {
      main: "#bb956e",
      contrastText: "#fff",
    },
    secondary: {
      main: "#5ba36b",
    },
  },
  overrides: {
    MuiFab: {
      extended: {
        borderRadius: 10,
      },
    },
    MuiChip: {
      root: {
        borderRadius: 10,
      },
    },
  },
});

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <ScrollTop />
          <header>
            <Grid container alignItems="center" justify="center">
              <Grid item>
                <Link component={RouterLink} to="/">
                  <img src="/Icon.png" alt="Logo" width="64" />
                </Link>
              </Grid>
            </Grid>
          </header>
          <Container className={classes.container}>
            <Route path="/" exact component={ShopList} />
            <Route path="/:name/:id" exact component={ShopDetail} />
            <Route path="/impressum" exact component={Impressum} />
            <Route path="/datenschutz" exact component={DataPrivacy} />
          </Container>
          <footer>
            <Container disableGutters>
              <Box padding={2}>
                <Grid container justify="center" spacing={5}>
                  <Grid item>
                    <Link component={RouterLink} to="/impressum">
                      Impressum
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link component={RouterLink} to="/Datenschutz">
                      Datenschutz
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </footer>
        </Router>
        <CookieConsent />
      </ApolloProvider>
    </ThemeProvider>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "100vh",
    },
  },
}));

export default App;
