import React from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { shopQuery, ShopResult } from "../graphql/shop";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ShopOutlined } from "@material-ui/icons";
import buildImageUrl from "../utils/buildImageUrl";

export default function ShopList(): JSX.Element {
  const classes = useStyles();
  const { id, name } = useParams<{ id: string; name: string }>();
  const { data: { shop } = {}, loading } = useQuery<ShopResult>(shopQuery, {
    variables: { id: parseInt(id, 10) },
  });

  if (loading) {
    return (
      <Grid container justify="center" alignContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  if (shop === undefined) {
    return (
      <Grid container justify="center" alignContent="center">
        <Alert severity="error">
          Shop mit dem Namen "{name}" nicht gefunden
        </Alert>
      </Grid>
    );
  }

  const {
    image,
    name: shopName,
    description,
    url,
    country,
    city,
    labels,
    vegan,
  } = shop;

  const imageUrl = image?.id ? buildImageUrl(image.id) : "";

  return (
    <div className={classes.gridWrapper}>
      <meta
        property="og:title"
        content={`Entdecke ${shopName} auf Shop-Fair.de`}
      />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <Grid
        className={classes.grid}
        container
        justify="space-around"
        alignItems="center"
        alignContent="center"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <img src={imageUrl} alt={shopName} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h3" gutterBottom>
            {shopName}
          </Typography>
          <Typography gutterBottom>{description}</Typography>
          <Box paddingY={4}>
            <Button
              href={url}
              color="primary"
              target="_blank"
              variant="outlined"
              startIcon={<ShopOutlined />}
            >
              Jetzt shoppen
            </Button>
            {/awin/i.test(url) && (
              <Box paddingTop={1}>
                <Typography variant="caption" display="block">
                  Dies ist ein Affiliate-Link, d.h. ich bekomme eine kleine
                  Provision, solltest du hierüber etwas kaufen.
                </Typography>
              </Box>
            )}
          </Box>

          <Grid container spacing={3} alignItems="flex-start">
            {labels.length > 0 && (
              <Grid item>
                <Typography color="primary">Labels</Typography>
                {labels.map(({ label: { name, image } }) => {
                  return image ? (
                    <img
                      src={`https://media.shop-fair.de${buildImageUrl(
                        image.id
                      )}`}
                      alt={name}
                      key={name}
                      width="120"
                    />
                  ) : (
                    <Typography key={name}>{name}</Typography>
                  );
                })}
              </Grid>
            )}
            {(country || city) && (
              <Grid item>
                <Typography color="primary">Kommt aus</Typography>
                <Typography>{city || country}</Typography>
              </Grid>
            )}
            {vegan !== "no" && (
              <Grid item>
                <Typography color="primary">Vegan</Typography>
                <Typography>
                  {vegan === "hasVeganProducts"
                    ? "Vegane Produkte"
                    : "100% Vegan"}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  grid: {
    [theme.breakpoints.up("sm")]: { minHeight: "80vh" },
  },
  gridWrapper: {
    background: "rgba(255,255,255,0.5)",
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: `${theme.spacing()}px 0`,
  },
}));
