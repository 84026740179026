import React, { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
} from "@material-ui/core";

const COOKIE_CONSENT_KEY = "cookies-sind-so-lecker";

export const setCookieConsentValue = (accept: boolean) =>
  localStorage.setItem(COOKIE_CONSENT_KEY, accept ? "true" : "false");

export const getCookieConsentValue = () => {
  const value = localStorage.getItem(COOKIE_CONSENT_KEY);

  if (value === null) {
    return undefined;
  }

  return value === "true" ? true : false;
};

export default function CookieConsent() {
  const [cookieStatus, setCookieStatus] = useState(getCookieConsentValue());

  const handleClick = (accept: boolean) => () => {
    setCookieConsentValue(accept);
    setCookieStatus(accept);
  };

  return (
    <Dialog open={cookieStatus === undefined}>
      <DialogTitle>Magst du auch Cookies?</DialogTitle>
      <DialogContent>
        Um unsere Seite mehr und mehr zu verbessern nutzen wir Google Analytics.
        <br />
        <br />
        Wir haben bei Google nachgefragt, ob sie aufhören können Cookies zu
        speichern – wollen sie nicht. Du würdest uns sehr helfen, wenn du
        Cookies zulässt.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick(false)}>Nein</Button>
        <Button variant="contained" color="primary" onClick={handleClick(true)}>
          Cookies erlauben
        </Button>
      </DialogActions>
    </Dialog>
  );
}
