import React, { useState } from "react";
import { Typography, Button, Link, Container } from "@material-ui/core";
import { setCookieConsentValue } from "./CookieConsent";
import { Check } from "@material-ui/icons";

export default function DataPrivacy(): JSX.Element {
  const [cookieDisabled, setCookieDisabled] = useState(false);

  return (
    <Container maxWidth="md">
      <Typography variant="h3" gutterBottom>
        Datenschutz
      </Typography>
      <br />
      <br />
      <Typography variant="h4" gutterBottom>
        Nutzung von Google Analytics (App und Web)
      </Typography>

      <Typography>
        Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren.
        Die daraus gewonnenen Daten werden genutzt, um unsere Website sowie
        Werbemaßnahmen zu optimieren.
        <br />
        <br />
        Google Analytics ist ein Webanalysedienst, der von Google Inc. (1600
        Amphitheatre Parkway, Mountain View, CA 94043, United States) betrieben
        und bereitgestellt wird. Google verarbeitet die Daten zur
        Website-Nutzung in unserem Auftrag und verpflichtet sich vertraglich zu
        Maßnahmen, um die Vertraulichkeit der verarbeiteten Daten zu
        gewährleisten.
        <br />
        <br />
        Während Ihres Website-Besuchs werden u.a. folgende Daten aufgezeichnet:
      </Typography>

      <br />
      <br />
      <Typography variant="h5" gutterBottom>
        Aufgerufene Seiten
      </Typography>
      <ul>
        <li>
          Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten
          und Verweildauer)
        </li>
        <li>Ihr ungefährer Standort (Land und Stadt)</li>
        <li>
          Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung
          möglich ist)
        </li>
        <li>
          Technische Informationen wie Browser, Internetanbieter, Endgerät und
          Bildschirmauflösung
        </li>
        <li>
          Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über
          welches Werbemittel Sie zu uns gekommen sind)
        </li>
        <li>
          Diese Daten werden an einen Server von Google in den USA übertragen.
          Google beachtet dabei die Datenschutzbestimmungen des „EU-US Privacy
          Shield“-Abkommens.
        </li>
      </ul>

      <Typography>
        Google Analytics speichert Cookies in Ihrem Webbrowser für die Dauer von
        zwei Jahren seit Ihrem letzten Besuch. Diese Cookies enthaltene eine
        zufallsgenerierte User-ID, mit der Sie bei zukünftigen Website-Besuchen
        wiedererkannt werden können. Die aufgezeichneten Daten werden zusammen
        mit der zufallsgenerierten User-ID gespeichert, was die Auswertung
        pseudonymer Nutzerprofile ermöglicht. Diese nutzerbezogenen Daten werden
        automatisch nach 14 Monaten gelöscht. Sonstige Daten bleiben in
        aggregierter Form unbefristet gespeichert. Sollten Sie mit der Erfassung
        nicht einverstanden sein, können Sie diese mit der einmaligen
        Installation des{" "}
        <Link
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="noopener noreferrer"
        >
          Browser-Add-ons zur Deaktivierung
        </Link>{" "}
        von Google Analytics unterbinden oder klicke einfach{" "}
        <Button
          startIcon={cookieDisabled ? <Check /> : undefined}
          onClick={() => {
            setCookieDisabled(true);
            setCookieConsentValue(false);
          }}
          disabled={cookieDisabled}
        >
          hier
        </Button>
        , um über unser Cookie-Consent alles zu deaktivieren.
        <br />
        <br />
        <small>Quelle: traffic3.net</small>
      </Typography>
    </Container>
  );
}
