import { gql } from "@apollo/client";

export const listShops = gql`
  query ShopFairShop {
    shops: ShopFairShop(limit: -1) {
      id
      name
      url
      description
      country
      city
      vegan
      image {
        id
      }
      categories {
        category: ShopFairCategory_id {
          name
        }
      }
    }
  }
`;

export interface Shop {
  id: string;
  name: string;
  url: string;
  description: string;
  country: string;
  city: null | string;
  vegan: Vegan;
  image: { id: string } | null;
  categories: Category[];
}
export interface Category {
  category: { name: string };
}

export type Vegan = "hasVeganProducts" | "is100PercentVegan" | "no";

export type ListShops = {
  shops: Shop[];
};
