import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { ListShops, listShops } from "../graphql/listShops";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { FilterListOutlined, SearchRounded } from "@material-ui/icons";
import GoogleAd from "./GoogleAd";
import buildImageUrl from "../utils/buildImageUrl";

export default function ShopList(): JSX.Element {
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const { data, loading } = useQuery<ListShops>(listShops);
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [veganFilter, setVeganFilter] = useState<boolean>(false);

  const categoryNames = (data?.shops || [])
    .reduce(
      (result: string[], { categories: shopCategories }) => [
        ...result,
        ...shopCategories.map(({ category }) => category.name),
      ],
      []
    )
    .filter((v, i, a) => a.indexOf(v) === i)
    .sort();

  const shops = (data?.shops || [])
    .filter(({ vegan }) => veganFilter === false || vegan !== "no")
    .filter(
      shop => search.trim() === "" || JSON.stringify(shop).includes(search)
    )
    .filter(({ categories: shopCategories }) => {
      if (categoriesFilter.length === 0) {
        return true;
      }
      return (
        categoriesFilter.filter(
          cat =>
            shopCategories.find(({ category }) => category.name === cat) !==
            undefined
        ).length > 0
      );
    })
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  useEffect(
    () => window.scrollTo({ behavior: "smooth", top: 0 }),
    [categoriesFilter, veganFilter]
  );

  if (loading) {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.preloadWrapper}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <meta property="og:title" content="Shop-Fair.de" />
      <meta
        property="og:description"
        content="Eco Fashion &amp; Green Lifestyle: Finde jetzt deinen Lieblingsshop!"
      />
      <meta property="og:image" content="https://shop-fair.de/Icon.png" />
      <Grid
        container
        justify="center"
        alignItems="center"
        className="shops"
        spacing={4}
      >
        {shops.map(({ id, name, image, vegan }, index) => (
          <React.Fragment key={id}>
            {index % 8 === 0 && index !== 0 && (
              <Grid item xs={12} sm={6} lg={4} xl={3}>
                <GoogleAd />
              </Grid>
            )}
            <Grid item xs={12} sm={6} lg={4} xl={3}>
              <div className="shop">
                <div
                  className="background lazyload"
                  data-bg={image ? buildImageUrl(image.id) : ""}
                />
                <Typography variant="h6" component="h3">
                  {name}
                </Typography>
                {vegan !== "no" && (
                  <div className="veganLabel">
                    <Typography>
                      {vegan === "hasVeganProducts"
                        ? "Vegane Produkte"
                        : "100% Vegan"}
                    </Typography>
                  </div>
                )}
                <Link to={`/${name}/${id}`} />
              </div>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Dialog open={openFilter} onClose={() => setOpenFilter(false)}>
        <DialogTitle>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>Shops filtern</Grid>
            <Grid item>
              <TextField
                value={search}
                variant="outlined"
                margin="dense"
                onChange={e => setSearch(e.currentTarget.value)}
                InputProps={{ startAdornment: <SearchRounded /> }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <Box padding={2}>
          <Grid container spacing={2} justify="center">
            {categoryNames.map(cat => (
              <Grid item key={cat}>
                <Chip
                  label={cat}
                  color={categoriesFilter.includes(cat) ? "primary" : "default"}
                  onClick={() => {
                    const newCategoriesFilter = [...categoriesFilter];
                    if (categoriesFilter.includes(cat)) {
                      newCategoriesFilter.splice(
                        categoriesFilter.indexOf(cat),
                        1
                      );
                    } else {
                      newCategoriesFilter.push(cat);
                    }
                    setCategoriesFilter(newCategoriesFilter);
                  }}
                />
              </Grid>
            ))}

            <Grid item>
              <Chip
                label="Vegan"
                color={veganFilter ? "primary" : "default"}
                onClick={() => {
                  setVeganFilter(!veganFilter);
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          {(categoriesFilter.length > 0 ||
            veganFilter ||
            search.trim() !== "") && (
            <Button
              onClick={() => {
                setCategoriesFilter([]);
                setVeganFilter(false);
                setSearch("");
              }}
            >
              Filter löschen
            </Button>
          )}
          <Box padding={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenFilter(false)}
            >
              {shops.length} Shop{shops.length !== 1 ? "s" : ""} erkunden
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <div className={classes.filterButton}>
        <Fab
          onClick={() => setOpenFilter(true)}
          variant="extended"
          color="primary"
        >
          <FilterListOutlined /> Filter
        </Fab>
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  filterButton: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  preloadWrapper: {
    minHeight: "calc(100vh - 110px)",
  },
}));
