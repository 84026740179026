import { gql } from "@apollo/client";

export const shopQuery = gql`
  query ShopFairShop($id: ID!) {
    shop: ShopFairShop_by_id(id: $id) {
      id
      name
      url
      description
      country
      city
      vegan
      image {
        id
      }
      categories {
        category: ShopFairCategory_id {
          name
        }
      }
      labels {
        label: ShopFairLabel_id {
          id
          name
        }
      }
    }
  }
`;

export interface Shop {
  id: string;
  name: string;
  url: string;
  description: string;
  country: string;
  city: null | string;
  vegan: Vegan;
  image: { id: string } | null;
  categories: Category[];
  labels: Label[];
}
export interface Category {
  category: { name: string };
}
export interface Label {
  label: { name: string; image: { id: string } | null };
}

export type Vegan = "hasVeganProducts" | "is100PercentVegan" | "no";

export type ShopResult = {
  shop: Shop;
};
